<template lang="pug">
div
  van-sticky(v-if='!isComponent')
    van-search(v-model='searchKey', placeholder='请输入搜索关键词' @search="onSearch" show-action shape="round")
      template(#action='')
        div(@click='onSearch') 搜索
    van-dropdown-menu
      van-dropdown-item(v-model='active', :options='options')
  pull-refresh-list(
    ref="list"
    @update:list="list = $event"
    :getListMethod="getListData"
    )
    van-card.van-hairline--bottom(
      v-for='item in list' :key='item.id' 
      :tag="item.tag"
      :title='item.name'
      :desc='item.desc'
      @click="$router.push(item.to)"
      )
      template(#thumb)
        van-icon(name="records" size="80")
      template(#tags)
        van-rate(:value="item.feasibility")
      template(#price)
        | {{item.amount}} 万元
  van-goods-action(v-if='!isComponent' style="margin:0px 40px;bottom:60px;background-color:transparent;")
    van-goods-action-button(type="info" text="快速报备" @click="$router.push({name: 'fastReport'})")
</template>

<script>
import { Sticky, Rate } from 'vant'
import { mapGetters } from 'vuex'
export default {
  props: ['isComponent', 'value', 'ofcCompanys'],
  components: {
    [Sticky.name]: Sticky,
    [Rate.name]: Rate
  },
  data() {
    return {
      searchKey: '',
      active: 'all',
      options: [
        { text: '全部项目', value: 'all' }
      ],
      list: [
        // {
        //   name: '商品名称',
        //   image: 'https://img.yzcdn.cn/vant/apple-1.jpg',
        //   to: {
        //     name: 'detail'
        //   }
        // }
      ],
      flowStatus: {}
    }
  },
  computed: {
    ...mapGetters(['getFlow']),
    userId() {
      return this.$store.state.userInfo.user_poi_users
    },
    sellerId() {
      return this.$store.state.userInfo.seller_poi_sell_users
    }
  },
  watch: {
    active() {
      this.$refs.list.refresh()
    }
  },
  methods: {
    onConfirm() {
      this.$refs.item.toggle()
    },
    onSearch() {
      this.$refs.list.refresh()
    },
    
    async getListData(params) {
      params.where = {}
      switch(this.active) {
        case 'all':
          break
        default: 
          params = {
            ...params,
            where: {
              state: this.active
            }
          }
          break
        // case 'wait':
        //   params = {
        //     ...params,
        //     where: {
        //       state: this.active
        //     }
        //   }
        //   break
        // case 'other':
        //   params = {
        //     ...params,
        //     where: {
        //       state: {
        //         $ne: 'wait'
        //       }
        //     }
        //   }
        //   break
      }
      let stId = (this.$store.state.userInfo.sell_users || {}).st_id_poi_company_stores || 0
      params.where = {
        ...params.where,
        user_poi_users: {
          // 后台添加的数据没有userid
          $in: [this.userId, 0]
        },
      //   st_id_poi_company_stores: {
      //     $in: [0]
      //   },
        seller_poi_sell_users: this.sellerId
      }
      if(stId != 0) {
        // 后台添加的数据没有st_id_poi_company_stores
        params.where.st_id_poi_company_stores = {
          $in: [stId, 0]
        }
      }
      if(this.ofcCompanys && this.ofcCompanys != 0 && this.ofcCompanys != '0') {
        params.where.id_poi_ofc_cus_companys = this.ofcCompanys
      }
      let res = await this.$api.GetProjects(params)
      this.list = this.list.concat(res.data.items.map((item) => {
        return {
          id: item.id,
          tag: this.flowStatus[item.state].alias,
          name: item.name,
          amount: item.amount,
          desc: item.first_party_name,
          feasibility: parseInt(item.feasibility),
          to: {
            name: 'projectDetail',
            query: {
              id: item.id,
              state: item.state,
            
            }
          }
        }
      }))
      return res.data.items.length
    }
  },
  async created() {
    let res = await this.getFlow('report_state')
    this.flowStatus = res.status
    if (res.status) {
      let keys = Object.keys(res.status)
      let status = keys.map((item) => {
        return { 
          value: item,
          text: res.status[item].alias
        }
      })
      this.options = this.options.concat(status)
    }
    this.searchKey = this.$route.query.searchKey
    if (this.searchKey) {
      this.onSearch()
    }
  
  },
  mounted() {
    // 
    this.$emit('on-nav', {
      leftText: '返回',
      leftArrow: true,
      // rightText: '新建项目',
      onClickLeft: () => {
        this.$router.push({name: 'person'})
      },
      // onClickRight: () => {
      //   this.$router.push({name: 'newProject'})
      // }
    })
  }
}
</script>
